<template>
    <div
        class="mx-4 attribute-view"
        :class="{ 'new-print-page': attribute.id % 2 === 0 }"
    >
        <h6
            class="ml-4 text-sticky"
            v-text="attribute.name"
        />

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <template v-for="(item, index) in getGeneralSummaryTable.columnsHeader">
                            <th
                                :key="index"
                                class="text-center"
                                v-if="getGeneralSummaryTable.columnIndexToShow[index]"
                                v-html="item"
                            />
                        </template>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(behavior, rowIndex) in classifiers.behaviors.filter(
                            behavior => behavior.attributes_id === attribute.id
                        )"
                        :key="`behavior${behavior.id}`"
                    >
                        <td>
                            <span v-text="behavior.name" />
                        </td>
                        <template v-for="(item, itemIndex) in getGeneralSummaryTable.data[rowIndex]">
                            <td
                                v-if="getGeneralSummaryTable.columnIndexToShow[itemIndex]"
                                class="text-center"
                                :class="getColorText(item, itemIndex, rowIndex)"
                            >
                                <span v-text="item || 0" />
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td></td>
                        <template
                            v-for="(item, itemIndex) in getGeneralSummaryTable.data[
                                getGeneralSummaryTable.ROWS_COUNT - 1
                            ]"
                        >
                            <td
                                v-if="getGeneralSummaryTable.columnIndexToShow[itemIndex]"
                                class="text-center"
                                :class="getColorText(item, itemIndex, getGeneralSummaryTable.ROWS_COUNT - 1)"
                            >
                                <span v-text="item || 0" />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <h6
            class="ml-4 mt-8 text-post"
            v-text="$t('evaluation.stats.dimensions.attribute.subtitleChart')"
        />

        <div class="attribute-chart">
            <apexchart
                width="100%"
                height="300"
                :options="getChartData.options"
                :series="getChartData.series"
                class="mx-auto mb-12"
            />
        </div>

        <template v-if="getAttributesDispersionValues.evaluationsTypesAvailable.pairs">
            <h6
                class="ml-4 mt-8 text-post"
                v-text="$t('evaluation.stats.dimensions.attribute.pairsDispersion')"
            />

            <div class="attribute-chart">
                <apexchart
                    width="100%"
                    height="300"
                    :options="getChartDispersionData.pairOptions"
                    :series="getChartDispersionData.pairsDispersionSerie"
                    class="mx-auto mb-12"
                />
            </div>
        </template>

        <template v-if="getAttributesDispersionValues.evaluationsTypesAvailable.collaborators">
            <h6
                class="ml-4 mt-8 text-post"
                v-text="$t('evaluation.stats.dimensions.attribute.collaboratorsDispersion')"
            />

            <div class="attribute-chart">
                <apexchart
                    width="100%"
                    height="300"
                    :options="getChartDispersionData.collaboratorOptions"
                    :series="getChartDispersionData.collaboratorsDispersionSerie"
                    class="mx-auto mb-12"
                />
            </div>
        </template>

        <AttributeGeneralStats
            class="mt-1 new-print-page"
            :attribute="attribute"
            :classifiers="classifiers"
            :userData="userData"
            :isOpen="isOpen"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';
import stickyWhenOpenMixin from '@/mixins/stickyWhenOpenMixin';
import AttributeGeneralStats from '@/components/games/evaluations/results/AttributeGeneralStats';

// 0-Autovaloración, 1-Responsable 1, 2-Responsable 2, 3-Pares, 4-Colaboradores
const COLORS = ['#c00000', '#1f4e79', '#1f4e79', '#00b050', '#ffc000'];

export default {
    mixins: [evaluationResultMixin, stickyWhenOpenMixin],
    components: { AttributeGeneralStats },
    props: {
        attribute: {
            type: Object,
            required: true
        },
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('statistics', [
            'getUserEvaluations',
            'getBehaviorEvaluationTypeDataStats',
            'getAttributesDispersionValues'
        ]),

        getGeneralSummaryTable() {
            const ROWS_COUNT = 4; // dimensions and totals

            const { behaviorsMatrixAvg, columnIndexToShow, COLUMNS_COUNT } = this.getBehaviorEvaluationTypeDataStats;

            const firstBehaviorPosition = (this.attribute.id - 1) * 3;

            const data = Array.from({ length: ROWS_COUNT - 1 }, (value, index) => {
                return behaviorsMatrixAvg[index + firstBehaviorPosition];
            });

            // Average result
            data.push(new Array(COLUMNS_COUNT)); // last row for column avg
            for (let columnIndex = 0; columnIndex < COLUMNS_COUNT; columnIndex++) {
                let sum = 0;
                let countValidItems = 0;

                for (let rowIndex = 0; rowIndex < ROWS_COUNT - 1; rowIndex++) {
                    const currentValue = data[rowIndex][columnIndex];

                    if (currentValue) {
                        sum += currentValue;
                        countValidItems++;
                    }
                }

                data[ROWS_COUNT - 1][columnIndex] = countValidItems
                    ? this.numberHelper.round(sum / countValidItems, 2)
                    : 0;
            }

            // 0-Autovaloración, 1-Responsable 1, 2-Responsable 2, 3-Pares, 4-Colaboradores
            const columnsHeader = [
                this.$t('evaluation.stats.personsType.selfAppraisal'),
                this.$t('evaluation.stats.personsType.responsible1'),
                this.$t('evaluation.stats.personsType.responsible2'),
                this.$t('evaluation.stats.personsType.pairs'),
                this.$t('evaluation.stats.personsType.collaborators')
            ];

            return { data, columnsHeader, columnIndexToShow, ROWS_COUNT, COLUMNS_COUNT };
        },

        getChartData() {
            const { data, columnsHeader, columnIndexToShow, ROWS_COUNT, COLUMNS_COUNT } = this.getGeneralSummaryTable;

            const dataSerie = Array.from({ length: COLUMNS_COUNT }, (value, index) => {
                return {
                    x: columnsHeader[index],
                    y: data[ROWS_COUNT - 1][index]
                };
            });

            const series = [
                {
                    data: dataSerie.filter((item, index) => columnIndexToShow[index]),
                    name: this.$t('evaluation.stats.dimensions.attribute.average')
                }
            ];

            // Options for config chart
            //const MAX_LENGTH = this.$vuetify.breakpoint.mobile ? 15 : 30;

            const options = {
                chart: {
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                legend: {
                    show: false
                },
                colors: COLORS.filter((item, index) => columnIndexToShow[index])
            };

            return { series, options };
        },

        getChartDispersionData() {
            const { pairsDispersion, collaboratorsDispersion, evaluationsTypesAvailable } =
                this.getAttributesDispersionValues;

            const COUNT_COLUMNS = 7;
            const attributeIndex = this.attribute.id - 1;
            let pairsDispersionSerie = new Array(COUNT_COLUMNS);
            let collaboratorsDispersionSerie = new Array(COUNT_COLUMNS);

            const NO_EVALUATE_TEXT = this.$t('evaluation.noEvaluate');
            for (let index = 0; index < COUNT_COLUMNS; index++) {
                const indexDispersion = index === 0 ? COUNT_COLUMNS - 1 : index - 1;

                if (evaluationsTypesAvailable.pairs) {
                    pairsDispersionSerie[indexDispersion] = {
                        x: indexDispersion === COUNT_COLUMNS - 1 ? NO_EVALUATE_TEXT : `${indexDispersion + 1}`,
                        y: pairsDispersion[attributeIndex][index]
                    };
                }

                if (evaluationsTypesAvailable.collaborators) {
                    collaboratorsDispersionSerie[indexDispersion] = {
                        x: indexDispersion === COUNT_COLUMNS - 1 ? NO_EVALUATE_TEXT : `${indexDispersion + 1}`,
                        y: collaboratorsDispersion[attributeIndex][index]
                    };
                }
            }

            pairsDispersionSerie = [
                { name: this.$t('evaluation.stats.dimensions.attribute.pairsEvaluation'), data: pairsDispersionSerie }
            ];
            collaboratorsDispersionSerie = [
                {
                    name: this.$t('evaluation.stats.dimensions.attribute.collaboratorsEvaluation'),
                    data: collaboratorsDispersionSerie
                }
            ];

            // Options for config chart
            //const MAX_LENGTH = this.$vuetify.breakpoint.mobile ? 15 : 30;

            const pairOptions = this.getDispersionChartOptions({ isPair: true });
            const collaboratorOptions = this.getDispersionChartOptions({ isPair: false });

            return { pairsDispersionSerie, collaboratorsDispersionSerie, pairOptions, collaboratorOptions };
        }
    },
    methods: {
        getColorText(value, itemIndex, rowIndex) {
            const { data } = this.getGeneralSummaryTable;

            const difference = value - data[rowIndex][0];

            return difference < 0 ? 'text-red' : difference > 0 ? 'text-green' : '';
        },

        getDispersionChartOptions({ isPair }) {
            return {
                chart: {
                    type: 'bar'
                },
                colors: [isPair ? COLORS[3] : COLORS[4]],
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                        formatter: value => `${value} ${this.$t('evaluation.stats.dimensions.attribute.points')}`
                    },
                    y: {
                        formatter: value => value
                    }
                },
                xaxis: {
                    show: true,

                    title: {
                        text: this.$t('evaluation.stats.dimensions.attribute.scores'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    }
                },
                yaxis: {
                    show: true,
                    title: {
                        text: this.$t('evaluation.stats.dimensions.attribute.numberOfEvaluations'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '12px',
                            fontWeight: 400
                        }
                    }
                },

                legend: {
                    show: true,
                    showForSingleSeries: true,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'top',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontWeight: 400,
                    formatter: undefined
                }
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.attribute-view {
    margin-bottom: 5rem;

    .attribute-chart {
        @media (min-width: 991px) {
            margin-left: 30%;
            margin-right: 30%;
        }
    }
}
</style>
