<template>
    <div>
        <h6
            class="ml-4"
            v-text="$t('evaluation.stats.general.subtitle1')"
        />

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <template v-for="(item, index) in getGeneralSummaryTable.columnsHeader">
                            <th
                                :key="index"
                                class="text-center"
                                v-if="getGeneralSummaryTable.columnToShow[index]"
                                v-html="item"
                            />
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(dimension, index) in classifiers.dimensions">
                        <tr :key="dimension.id">
                            <td
                                colspan="100%"
                                v-text="dimension.name"
                                class="primary"
                            />
                        </tr>

                        <tr
                            v-for="(attribute, index) in classifiers.attributes.filter(
                                att => att.dimensions_id === dimension.id
                            )"
                            :key="`attribute${attribute.id}`"
                        >
                            <td>
                                <ul>
                                    <li v-text="attribute.name" />
                                </ul>
                            </td>
                            <template v-for="(item, itemIndex) in getGeneralSummaryTable.data[attribute.id - 1]">
                                <td
                                    v-if="getGeneralSummaryTable.columnToShow[itemIndex]"
                                    class="text-center"
                                    :class="getColorText(item, itemIndex)"
                                >
                                    <span
                                        v-if="isGapColumn(itemIndex) && Math.abs(item) >= 0.7"
                                        class="font-weight-bold"
                                        v-text="item"
                                    />
                                    <span
                                        v-else
                                        v-text="item || 0"
                                    />
                                </td>
                            </template>
                        </tr>
                    </template>

                    <tr>
                        <td></td>
                        <template
                            v-for="(item, itemIndex) in getGeneralSummaryTable.data[
                                getGeneralSummaryTable.ROWS_COUNT - 1
                            ]"
                        >
                            <td
                                v-if="getGeneralSummaryTable.columnToShow[itemIndex]"
                                class="text-center"
                                :class="getColorText(item, itemIndex)"
                            >
                                <span
                                    v-if="isGapColumn(itemIndex) && Math.abs(item) >= 0.7"
                                    class="font-weight-bold"
                                    v-text="item"
                                />
                                <span
                                    v-else
                                    v-text="item || 0"
                                />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

export default {
    mixins: [evaluationResultMixin],
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        getGeneralSummaryTable: {
            type: Object,
            required: true
        }
    },

    methods: {
        isGapColumn(index) {
            return index >= 4;
        },

        getColorText(value, index) {
            if (!this.isGapColumn(index)) return '';

            return value < 0 ? 'text-red' : value > 0 ? 'text-green' : '';
        }
    }
};
</script>

<style lang="scss" scoped></style>
