<template>
    <div class="qualitative-questions">
        <div v-if="getQualitativeInfo.answersC1.length">
            <h6
                class="ml-4 mb-0 text-sticky"
                v-text="$t('evaluation.stats.quantitativeQuestions.subtitle1')"
            />

            <p
                v-for="(answer, index) in getQualitativeInfo.answersC1"
                :key="`c1${index}`"
                class="qualitative-questions__answer"
                v-text="answer"
            ></p>
        </div>

        <div v-if="getQualitativeInfo.answersC2.length">
            <h6
                class="ml-4 mb-0 mt-10 text-sticky"
                v-text="$t('evaluation.stats.quantitativeQuestions.subtitle2')"
            />

            <p
                v-for="(answer, index) in getQualitativeInfo.answersC2"
                :key="`c2${index}`"
                class="qualitative-questions__answer"
                v-text="answer"
            ></p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stickyWhenOpenMixin from '@/mixins/stickyWhenOpenMixin';

export default {
    mixins: [stickyWhenOpenMixin],
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        isDataLoaded: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters('statistics', ['getUserEvaluations']),

        getQualitativeInfo() {
            const data = this.getUserEvaluations?.data ?? [];
            const answersC1 = [];
            const answersC2 = [];

            data.forEach(evaluationResult => {
                const currentAnswer1 = evaluationResult['c1']?.trim() || '';
                const currentAnswer2 = evaluationResult['c2']?.trim() || '';

                if (currentAnswer1) {
                    answersC1.push(currentAnswer1);
                }
                if (currentAnswer2) {
                    answersC2.push(currentAnswer2);
                }
            });

            return { answersC1, answersC2 };
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.qualitative-questions {
    &__answer {
        background-color: #f9f9f1;
        margin: 0.5rem;
        padding: 0.5rem;
        border-radius: 5px 15px 0 12px;
        overflow-wrap: break-word;
    }
}
</style>
