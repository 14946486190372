div
<template>
    <div>
        <AttributeView
            v-for="attribute in classifiers.attributes.filter(attribute => attribute.dimensions_id == dimension.id)"
            :key="attribute.id"
            :attribute="attribute"
            :classifiers="classifiers"
            :userData="userData"
            :isOpen="isOpen"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

import AttributeView from '@/components/games/evaluations/results/AttributeView';

export default {
    mixins: [evaluationResultMixin],
    components: { AttributeView },
    props: {
        dimension: {
            type: Object,
            required: true
        },
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        // ...mapGetters('statistics', ['getUserEvaluations', 'getAttributeEvaluationTypeDataStats'])
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
