<template>
    <div class="px-4">
        <h6
            class="ml-4 mb-0"
            v-text="$t('evaluation.stats.general.subtitle2')"
        />

        <apexchart
            v-if="isDataLoaded"
            width="100%"
            height="450"
            :options="getChartOptions"
            :series="getChartSeries"
            class="mx-auto"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

export default {
    mixins: [evaluationResultMixin],
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        isDataLoaded: {
            type: Boolean,
            required: true
        },
        getColumnSortedDataTableInfo: {
            type: Object,
            required: true
        }
    },
    computed: {
        getChartSeries() {
            const seriesLabel = [
                this.$t('evaluation.stats.personsType.selfAppraisal'),
                this.$t('evaluation.stats.personsType.responsible'),
                this.$t('evaluation.stats.personsType.pairs'),
                this.$t('evaluation.stats.personsType.collaborators')
            ];

            const colors = ['#c00000', '#1f4e79', '#00b050', '#ffc000'];

            const { attributeAvgMatrixWithCustomColumns: attributeAvgMatrix, columnsIndexToShow } =
                this.getColumnSortedDataTableInfo;

            const series = Array.from({ length: 4 }, (v, index) => {
                const data = attributeAvgMatrix.map(row => row[index]).slice(0, 6);

                const color = colors[index];

                return { name: seriesLabel[index], data, color };
            });

            for (let index = seriesLabel.length - 1; index >= 0; index--) {
                if (!columnsIndexToShow[index]) {
                    series.splice(index, 1);
                }
            }

            return series;
        },

        getChartOptions() {
            const MAX_LENGTH = this.$vuetify.breakpoint.mobile ? 15 : 30;

            const chartOptions = {
                chart: {
                    type: 'radar'
                },
                xaxis: {
                    categories: this.classifiers?.attributes ?? [],
                    labels: {
                        show: true,
                        style: {
                            fontSize: '10px',
                            fontWeight: '600',
                            colors: Array(6).fill('#6b6a6a')
                        },
                        formatter: function (attribute) {
                            if (attribute.id === 1 || attribute.id === 4) return attribute.name;

                            var words = attribute.name.split(' ');
                            let lines = [];
                            let currentLine = '';

                            for (let i = 0; i < words.length; i++) {
                                const word = words[i];
                                if (!currentLine.length || currentLine.length + word.length <= MAX_LENGTH) {
                                    currentLine += word + ' ';
                                } else {
                                    lines.push(currentLine.trim());
                                    currentLine = word + ' ';
                                }
                            }

                            if (currentLine.length > 0) {
                                lines.push(currentLine.trim());
                            }

                            return lines;
                        },
                        width: 20,
                        maxWidth: 20,
                        maxHeight: 5
                    }
                }
            };

            return chartOptions;
        }
    }
};
</script>

<style lang="scss"></style>
