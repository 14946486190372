<template>
    <div>
        <GeneralTable
            :classifiers="classifiers"
            :userData="userData"
            :getGeneralSummaryTable="getGeneralSummaryTable"
        />

        <GeneralChart
            class="mt-8"
            :classifiers="classifiers"
            :isDataLoaded="isDataLoaded"
            :getColumnSortedDataTableInfo="getColumnSortedDataTableInfo"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

import GeneralTable from '@/components/games/evaluations/results/GeneralTable';
import GeneralChart from '@/components/games/evaluations/results/GeneralChart';

export default {
    mixins: [evaluationResultMixin],
    components: { GeneralTable, GeneralChart },
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        isDataLoaded: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters('statistics', ['getUserEvaluations', 'getAttributeEvaluationTypeDataStats']),

        getColumnSortedDataTableInfo() {
            /* EvaluationTypes position
                    selfAppraisal: 0,
                    responsible: 1,
                    collaborators: 2,
                    pairs: 3
            */

            // NEW POSITIONS: [selfAppraisal, responsible (previous collaborators), pairs, collaborators(previous responsible) ]
            const EVALUATION_TYPES_POSITION_COLUMNS = [0, 2, 3, 1];

            const { matrixAvg: attributeAvgMatrix, evaluationTypesExistIdMap } =
                this.getAttributeEvaluationTypeDataStats;

            const attributeAvgMatrixWithCustomColumns = Array.from(
                { length: attributeAvgMatrix.length },
                (v, k) => new Array(attributeAvgMatrix[k].length)
            );

            for (let indexRow = 0; indexRow < attributeAvgMatrix.length; indexRow++) {
                for (let indexColumn = 0; indexColumn < attributeAvgMatrix?.[0]?.length ?? 0; indexColumn++) {
                    const newColumnIndex = EVALUATION_TYPES_POSITION_COLUMNS[indexColumn];

                    attributeAvgMatrixWithCustomColumns[indexRow][indexColumn] =
                        attributeAvgMatrix[indexRow][newColumnIndex];
                }
            }

            const columnsIndexToShow = {};
            for (let index = 0; index < EVALUATION_TYPES_POSITION_COLUMNS.length; index++) {
                const previousColumnIndex = EVALUATION_TYPES_POSITION_COLUMNS[index];
                columnsIndexToShow[index] = evaluationTypesExistIdMap[previousColumnIndex + 1];
            }

            return { attributeAvgMatrixWithCustomColumns, columnsIndexToShow };
        },

        getGeneralSummaryTable() {
            const ROWS_COUNT = 7; // attributes and totals
            const COLUMNS_COUNT = 7;

            const { attributeAvgMatrixWithCustomColumns, columnsIndexToShow: columnsBasicIndexToShow } =
                this.getColumnSortedDataTableInfo;

            const data = Array.from({ length: ROWS_COUNT - 1 }, (v, k) =>
                attributeAvgMatrixWithCustomColumns[k].concat(
                    new Array(COLUMNS_COUNT - attributeAvgMatrixWithCustomColumns.length)
                )
            );

            // Gaps
            for (let index = 0; index < data.length; index++) {
                data[index][4] = this.numberHelper.round(data[index][1] - data[index][0], 2);
                data[index][5] = this.numberHelper.round(data[index][2] - data[index][0], 2);
                data[index][6] = this.numberHelper.round(data[index][3] - data[index][0], 2);
            }

            // Average result
            data.push(new Array(COLUMNS_COUNT)); // last row for column avg
            for (let columnIndex = 0; columnIndex < COLUMNS_COUNT; columnIndex++) {
                let sum = 0;
                let countValidItems = 0;

                for (let rowIndex = 0; rowIndex < ROWS_COUNT - 1; rowIndex++) {
                    const currentValue = data[rowIndex][columnIndex];

                    if (currentValue) {
                        sum += currentValue;
                        countValidItems++;
                    }
                }

                if (columnIndex < 4) {
                    data[ROWS_COUNT - 1][columnIndex] = countValidItems
                        ? this.numberHelper.round(sum / countValidItems, 2)
                        : 0;
                } else {
                    // Gaps result
                    data[ROWS_COUNT - 1][columnIndex] = this.numberHelper.round(sum / (ROWS_COUNT - 1), 2);
                }
            }

            const columnToShow = Array.from({ length: COLUMNS_COUNT }, () => true);
            for (let index = 1; index < 4; index++) {
                if (!columnsBasicIndexToShow[index]) {
                    columnToShow[index] = false;
                    columnToShow[index + 3] = false;
                }
            }

            // [selfAppraisal, responsible (previous collaborators), pairs, collaborators(previous responsible) ]
            const columnsHeader = [
                this.$t('evaluation.stats.personsType.selfAppraisal'),
                this.$t('evaluation.stats.personsType.responsible'),
                this.$t('evaluation.stats.personsType.pairs'),
                this.$t('evaluation.stats.personsType.collaborators'),
                // Gaps
                this.$t('evaluation.stats.personsType.gap.responsible'),
                this.$t('evaluation.stats.personsType.gap.pairs'),
                this.$t('evaluation.stats.personsType.gap.collaborators')
            ];

            return { data, columnsHeader, columnToShow, ROWS_COUNT, COLUMNS_COUNT };
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
