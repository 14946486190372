export default {
    data: function () {
        return {
            showScrollButton: false
        };
    },
    methods: {
        scrollToTop() {
            var rootElement = document.documentElement;
            rootElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.showScrollButton = true;
            } else {
                this.showScrollButton = false;
            }
        });
    }
};
