export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        addStyleToStickyTitle() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const elements = document.getElementsByClassName('text-sticky');
                    for (let i = 0; i < elements?.length ?? 0; i++) {
                        elements[i].classList.add('text-sticky-top');
                    }
                }, 1500);
            });
        }
    },
    mounted() {
        this.addStyleToStickyTitle();
    },
    watch: {
        isOpen(value) {
            const elements = document.getElementsByClassName('text-sticky');
            for (let i = 0; i < elements?.length ?? 0; i++) {
                elements[i].classList.remove('text-sticky-top');
            }

            if (value) {
                this.addStyleToStickyTitle();
            }
        }
    }
};
