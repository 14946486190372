<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <template v-for="(item, index) in getAttributeStatsTable.columnsHeader">
                            <th
                                :key="index"
                                class="text-center"
                                v-if="getAttributeStatsTable.columnIndexToShow[index]"
                                v-html="item"
                            />
                        </template>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(item, rowIndex) in getAttributeStatsTable.statement"
                        :key="rowIndex"
                    >
                        <td>
                            <span v-text="item" />
                        </td>
                        <template v-for="(item, itemIndex) in getAttributeStatsTable.data[rowIndex]">
                            <td
                                v-if="getAttributeStatsTable.columnIndexToShow[itemIndex]"
                                class="text-center"
                                :key="`${rowIndex}-${itemIndex}`"
                            >
                                <span v-text="item || 0" />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

export default {
    mixins: [evaluationResultMixin],
    props: {
        attribute: {
            type: Object,
            required: true
        },
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters('statistics', ['getUserEvaluations']),

        getAttributeStatsTable() {
            const ROWS_COUNT = 4;
            const COLUMNS_COUNT = 2;

            const columnIndexToShow = {};

            const firstBehaviorPosition = (this.attribute.id - 1) * 3;

            const data = Array.from({ length: ROWS_COUNT }, (value, index) => {
                return Array(COLUMNS_COUNT).fill(0);
            });

            let extras = {}; // {"countPeopleExpected":{"pairs":0,"collaborators":1,"responsible":10}}
            const evaluationMatrix = this.getUserEvaluations?.data ?? [];
            const countPeopleWithResponse = { pairs: 0, collaborators: 0 };
            const countPeopleWithValidResponse = { pairs: 0, collaborators: 0 };

            const attributeId = this.attribute.id;
            evaluationMatrix?.forEach(evaluationResult => {
                if (evaluationResult.evaluation_type_id === 1) {
                    extras = evaluationResult.extras;
                } else if (evaluationResult.evaluation_type_id === 2) {
                    countPeopleWithResponse.collaborators++;

                    if (this.isValidAttribute(evaluationResult, attributeId)) {
                        countPeopleWithValidResponse.collaborators++;
                    }
                } else if (evaluationResult.evaluation_type_id === 4) {
                    countPeopleWithResponse.pairs++;

                    if (this.isValidAttribute(evaluationResult, attributeId)) {
                        countPeopleWithValidResponse.pairs++;
                    }
                }
            });

            // countPeopleExpected
            data[0][0] = extras?.countPeopleExpected?.pairs;
            data[0][1] = extras?.countPeopleExpected?.responsible;

            columnIndexToShow[0] = data[0][0];
            columnIndexToShow[1] = data[0][1];

            // countPeopleWithResponse
            data[1][0] = countPeopleWithResponse.pairs;
            data[1][1] = countPeopleWithResponse.collaborators;

            // countPeopleWithValidResponse
            data[2][0] = countPeopleWithValidResponse.pairs;
            data[2][1] = countPeopleWithValidResponse.collaborators;

            // countAnswerDeleted
            data[3][0] = (countPeopleWithResponse.pairs - countPeopleWithValidResponse.pairs) * 3;
            data[3][1] = (countPeopleWithResponse.collaborators - countPeopleWithValidResponse.collaborators) * 3;

            const columnsHeader = [
                this.$t('evaluation.stats.personsType.pairs'),
                this.$t('evaluation.stats.personsType.collaborators')
            ];

            const statement = [
                this.$t('evaluation.stats.dimensions.attribute.countPeopleExpected'),
                this.$t('evaluation.stats.dimensions.attribute.countPeopleWithResponse'),
                this.$t('evaluation.stats.dimensions.attribute.countPeopleWithValidResponse'),
                this.$t('evaluation.stats.dimensions.attribute.countAnswerDeleted')
            ];

            return { statement, data, columnsHeader, columnIndexToShow, ROWS_COUNT, COLUMNS_COUNT };
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
